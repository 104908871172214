import React, { FC, useContext, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { useAccountById, useIsAccountFavorite } from "../hooks";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import { motion, Variants } from "framer-motion";
import { useAxios } from "myAxios";
import { useRouter } from "next/router";
import GridEventContext from "components/context/GridEventContext";
import { useCounts } from "components/portfolio/hooks";

const useStyles = makeStyles(() => ({
  button: {
    "&:hover": {
      backgroundColor: "#FFF2ED",
    },
  },
  icon: {
    color: "#D93829",
  },
  container: {
    marginLeft: 4,
  },
}));

const variants: Variants = {
  loading: { scale: 1.2, opacity: 0.5 },
  loaded: { scale: 1, opacity: 1 },
};

const Card: FC<{ accountId?: string; disableJump?: boolean }> = ({
  accountId,
  disableJump = false,
}) => {
  const classes = useStyles();
  const { data } = useAccountById(accountId);
  const { data: isFavorite, mutate } = useIsAccountFavorite(data?.accountKey);
  const { mutate: overviewMutate } = useCounts();
  const axios = useAxios();
  if (!data) return null;
  // const [isFavorite, setIsFavorite] = useState(data.favorite);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { gridEvent } = useContext(GridEventContext);
  const onClick = () => {
    setLoading(true);
    if (isFavorite) {
      axios
        .delete(`/api/portfolio/favorite/${data.accountKey}`)
        .then(() => {
          mutate(false, false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post(`/api/portfolio/favorite/${data.accountKey}`)
        .then(() => {
          mutate(true, false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (
      router.pathname === "/spng/portfolio" ||
      router.pathname === "/spng/playbook"
    ) {
      //sync favorite for single row to do
      const node = gridEvent?.api?.getRowNode(data.accountKey);
      if (node) {
        node.updateData({ ...data, favorite: !isFavorite });
        gridEvent?.api.redrawRows({ rowNodes: [node] });
      }
      overviewMutate();
    }
  };

  return (
    <motion.div
      onClick={onClick}
      className={classes.container}
      animate={
        (loading || isFavorite === undefined) && !disableJump
          ? "loading"
          : "loaded"
      }
      variants={variants}
    >
      <IconButton size="small" disableRipple={true} className={classes.button}>
        {isFavorite ? (
          <Favorite className={classes.icon} />
        ) : (
          <FavoriteBorder className={classes.icon} />
        )}
      </IconButton>
    </motion.div>
  );
};

export default Card;
