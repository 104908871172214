import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ValueGetterParams, GridOptions } from "ag-grid-community";
import { Close } from "@material-ui/icons";
import MButton from "components/momentum/MButton";
import { FEATURE_KEY } from "constant";
import { useAxios } from "myAxios";
import { saveAs } from "file-saver";
import { Account } from "types/portfolio";
import {
  useAccountById,
  useAccountTeamContactById,
} from "components/summary/hooks";
import { AuthDetail } from "types/auth";

export interface AccountTeamDialogProps {
  dialogClose?: () => void;
  dialogStatus?: boolean;
  account?: Account;
  authDetail: AuthDetail;
}

const AccountTeamDialog: React.FC<AccountTeamDialogProps> = (props) => {
  const [open, setOpen] = useState(true);

  const { account, authDetail } = props;

  const isOnline = account
    ? account.sourceSystemCode?.includes("Online")
    : true;

  const { data: contacts, error } = useAccountTeamContactById(
    authDetail.features.includes(FEATURE_KEY.PORTFOLIO_ACCOUNT_TEAM) &&
      !isOnline
      ? account?.accountId
      : undefined
  );

  const { data: accountData } = useAccountById(account?.accountId);

  const config: GridOptions = {
    columnDefs: [
      {
        headerName: "Full Name",
        valueGetter: (params: ValueGetterParams) =>
          params.data.firstName + " " + params.data.lastName,
      },
      {
        headerName: "CEC ID",
        valueGetter: (params: ValueGetterParams) =>
          params.data.email.replace("@cisco.com", ""),
      },
      {
        headerName: "Job Title",
        field: "jobTitle",
        sort: "asc",
      },
    ],
    defaultColDef: {
      resizable: true,
      sortable: true,
    },
    tooltipShowDelay: 0,
    rowData: contacts,
    domLayout: "autoHeight",
    onFirstDataRendered: (params) => params.api.sizeColumnsToFit(),
    overlayNoRowsTemplate: "No available contact found",
  };

  const axios = useAxios();

  const canDownload = props.authDetail.features.includes(
    FEATURE_KEY.PORTFOLIO_CONTACTS_DOWNLOAD
  );

  const onClose = () => {
    setOpen(false);
    props.dialogClose?.();
  };

  const onDownload = () => {
    axios
      .post(
        `/api/portfolio/contactsDownload?category=teamContacts&page=portfolio&type=point`,
        {
          accounts: [{ ...accountData, teamContacts: contacts || [] }],
        }
      )
      .then((res) => {
        saveAs(
          new Blob([res.data], {
            type: res.headers["content-type"],
          }),
          res.headers.filename
        );
      });
  };

  return (
    <Dialog
      open={open}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={onClose}
    >
      <DialogTitle style={{ lineHeight: "1em" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>
            {account?.accountName}
          </span>
          <IconButton onClick={onClose} size="small">
            <Close style={{ color: "#121212", fillOpacity: 0.6 }} />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 8,
          }}
        >
          <span style={{ color: "#666", fontSize: "14px" }}>
            {account?.accountId}
          </span>
          <MButton
            buttonSize={28}
            buttonType="blue"
            disabled={!canDownload}
            onClick={onDownload}
          >
            Download
          </MButton>
        </div>
      </DialogTitle>
      <DialogContent dividers={true}>
        {isOnline ? (
          "No Data available"
        ) : contacts ? (
          <div className="ag-theme-material">
            <AgGridReact {...config} />
          </div>
        ) : error ? (
          "Fail to load data"
        ) : (
          "Loading..."
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccountTeamDialog;
