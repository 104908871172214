import React, { FC, useState, useRef, CSSProperties } from "react";
import { PopperProps, Fade } from "@material-ui/core";
import style from "./ArrowPopper.module.scss";
import dynamic from "next/dynamic";
import { useClickAway } from "ahooks";

interface ArrowPopperProps extends PopperProps {
  disableStyle?: boolean;
  handleClose?: () => void;
  disableArrow?: boolean;
  disableFlip?: boolean;
  pageId?: string;
  containerStyle?: CSSProperties;
}

const Popper = dynamic(() => import("@material-ui/core/Popper"), {
  ssr: false,
});

const ArrowPopper: FC<ArrowPopperProps> = ({
  disableStyle,
  handleClose,
  disableArrow,
  disableFlip,
  pageId,
  containerStyle,
  ...props
}) => {
  const [arrowRef, setArrowRef] = useState<null | HTMLSpanElement>(null);
  const ref = useRef(null);

  const handleArrowRef = (ref: HTMLSpanElement) => {
    setArrowRef(ref);
  };

  useClickAway(() => {
    handleClose?.();
  }, [ref, props.anchorEl as any]);

  return (
    <Popper
      className={style.root}
      transition={true}
      placement={pageId === "email-contact" ? "left" : "bottom"}
      modifiers={{
        flip: {
          enabled: !disableFlip,
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent",
        },
      }}
      {...props}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={{ exit: 0 }}>
          <div ref={ref}>
            {!disableArrow ? (
              <span className={style.arrow} ref={handleArrowRef} />
            ) : null}
            <div
              className={disableStyle ? "" : style.pop}
              style={containerStyle}
            >
              {props.children}
            </div>
          </div>
        </Fade>
      )}
    </Popper>
  );
};

export default ArrowPopper;
