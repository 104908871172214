import { useRouter } from "next/router";
import { useEffect, createContext } from "react";
import { AuthDetail } from "types/auth";
import { ROLE } from "constant";
const Index = () => {
  const route = useRouter();
  useEffect(() => {
    // route.push(`${IS_DEV ? "" : `${PATH_PREFIX}`}/portfolio`);
    route.push("/spng");
  }, [route]);

  return <></>;
};

export default Index;

export const AuthProvider = createContext<AuthDetail>({
  menus: [],
  features: [],
  role: ROLE.CISCO_WIDE_ROLE,
  toggles: undefined,
  emailId: "",
});
