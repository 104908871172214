import React, {
  FC,
  CSSProperties,
  useState,
  MouseEvent,
  useEffect,
} from "react";
import {
  Button,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Done, ExpandMore } from "@material-ui/icons";

const STATUS_CHIP_TYPE = [
  "PENDING",
  "ACTIVE",
  "INACTIVE",
  "BUNDLE",
  "OVERDUE",
  "NEW",
  "High Touch",
  "Scale",
  "system",
  "program",
  "personal",
  "Customer Activity",
  "Internal Activity",
  "Other",
  "Scale",
  "Longtail",
  "Care Agent Reactive",
  "Care Agent Proactive",
  "Activity w/no task",
  "N/A",
  "Yes",
  "No",
] as const;

export type StatusChipType = typeof STATUS_CHIP_TYPE[number];

interface StatusChipProps {
  type: StatusChipType | "Yes" | "No";
  style?: CSSProperties;
  editProps?: {
    options: StatusChipType[];
    editCallback?: (type: StatusChipType) => void;
  };
}

const PALETTE: Record<StatusChipType, CSSProperties> = {
  PENDING: { color: "#FC9D03", background: "#FFECC2" },
  ACTIVE: { color: "#00AB50", background: "rgba(0, 171, 80, 0.2)" },
  INACTIVE: { color: "#FF5F58", background: "#FFE1D9" },
  BUNDLE: { color: "white", background: "#875AE0" },
  OVERDUE: { color: "white", background: "#D6442A" },
  NEW: {
    color: "white",
    background: "linear-gradient(180deg, #82CF5F 0%, #00BCF5 100%)",
  },
  "High Touch": { color: "#999923", background: "#E7EDB7" },
  // Scale: { color: "#A87FF0", background: "#F0E3FC" },
  system: {
    color: "white",
    background: "linear-gradient(180deg, #00BCF5 0%, #A875FC 100%)",
  },
  program: {
    color: "white",
    background: "linear-gradient(180deg, #82CF5F 0%, #FBBD23 100%)",
  },
  personal: {
    color: "white",
    background: "linear-gradient(180deg, #A875FC 0%, #FF3A65 100%)",
  },
  "Customer Activity": { color: "#279BE8", background: "#D5EEFF" },
  "Internal Activity": {
    color: "#00AB50",
    background: "rgba(0, 171, 80, 0.2)",
  },
  Other: {
    color: "#6F739E",
    background: "#E3E7FA",
  },
  "Activity w/no task": {
    color: "#6F739E",
    background: "#E3E7FA",
  },
  Scale: { color: "#9B5FF5", background: "#F3EBFF" },
  Longtail: { color: "#6977F0", background: "#EBEDFF" },
  "Care Agent Reactive": { color: "#BA8C00", background: "#F7F6DC" },
  "Care Agent Proactive": { color: "#16A693", background: "#E9F7F3" },
  "N/A": {
    color: "#5D756B",
    background: "#F5F7F6",
  },
  Yes: { color: "#9B5FF5", background: "#F3EBFF" },
  No: { color: "#6977F0", background: "#EBEDFF" },
};

const useStyle = makeStyles(() => ({
  icon: {
    marginRight: 0,
  },
  pager: {
    width: "fit-content",
  },
}));

const StatusChip: FC<StatusChipProps> = ({ type, style, editProps }) => {
  const classes = useStyle();

  const [selected, setSelected] = useState<StatusChipType>(type);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChange = (value: StatusChipType) => () => {
    setSelected(value);
    editProps?.editCallback?.(value);
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!editProps) {
      setSelected(type);
    }
  }, [editProps, type]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          color: PALETTE[selected]?.color,
          fontSize: 11,
          background: PALETTE[selected]?.background,
          borderRadius: 2,
          fontWeight: STATUS_CHIP_TYPE.includes(selected) ? 700 : "normal",
          padding: "2px 8px",
          lineHeight: "16px",
          ...style,
        }}
      >
        {selected}
      </span>
      {editProps && (
        <>
          <Button
            style={{ padding: 0, minWidth: "unset", marginLeft: 8 }}
            classes={{ startIcon: classes.icon }}
            variant={"contained"}
            disableElevation={true}
            onClick={handleClick}
            startIcon={
              <ExpandMore
                style={{
                  color: PALETTE[selected]?.color,
                  background: PALETTE[selected]?.background,
                }}
              />
            }
          />
          <Menu
            classes={{ paper: classes.pager }}
            style={{ display: "flex" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorEl={anchorEl}
          >
            {editProps.options.map((item) => (
              <MenuItem
                key={item}
                value={item}
                selected={item === selected}
                onClick={handleChange(item)}
              >
                <ListItemText primary={item} />
                {selected === item ? <Done color="primary" /> : null}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

export default StatusChip;
