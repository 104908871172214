import { createMuiTheme } from "@material-ui/core/styles";

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#00a0d1",
    },
  },
  zIndex: {
    modal: 1250,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#EDEDED",
        },
      },
    },
    MuiTooltip: {
      arrow: {
        color: "white",
      },
      tooltip: {
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
        fontSize: 14,
        // border: "1px solid #009ecb",
        // borderLeftWidth: "5px !important",
        backgroundColor: "white",
        // padding: "16px !important",
        // borderRadius: "0 !important",
        color: "#171B1F",
        // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.16)",
        maxHeight: "20vh",
        overflowY: "auto",
        minWidth: 160,
      },
      popper: {
        zIndex: 2001,
      },
    },
    MuiInputLabel: {
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "inherit",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "inherit",
      },
    },
    // MuiButton: {
    //   root: {
    //     "&&:disabled": {
    //       boxShadow:
    //         "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    //     },
    //   },
    // },
    MuiButtonBase: {
      root: {
        "&&$disabled": {
          pointerEvents: "auto",
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        "&&::before": {
          display: "none",
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiChip: {
      label: {
        whiteSpace: "pre",
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#EDEDED",
        color: "#545454",
        fontSize: 14,
        fontWeight: 700,
      },
      body: {
        color: "#545454",
        fontSize: 14,
        fontWeight: 700,
      },
      root: {
        padding: 8,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 2px 4px rgb(0 0 0 / 10%)",
      },
    },
  },
});
