import { FC, useContext } from "react";
import { Avatar, AvatarProps } from "@material-ui/core";
import AppLayoutContext from "components/context/AppLayoutContext";

const CiscoAvatar: FC<AvatarProps & { username?: string; invert?: boolean }> = (
  props
) => {
  const { isCiscoNetwork } = useContext(AppLayoutContext);
  const { username, invert = false, ...rest } = props;
  return (
    <Avatar
      {...rest}
      style={{
        ...rest.style,
        filter: invert && !isCiscoNetwork ? "invert(1)" : rest.style?.filter,
      }}
      src={
        isCiscoNetwork && username
          ? `https://wwwin.cisco.com/dir/photo/zoom/${username}.jpg`
          : `/avatar.svg`
      }
    >
      {username?.charAt(0) || ""}
    </Avatar>
  );
};

export default CiscoAvatar;
