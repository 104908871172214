import { AppProps } from "next/app";
import Head from "next/head";
import { useEffect, useMemo } from "react";
import { lightTheme } from "theme";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { LicenseManager } from "ag-grid-enterprise";
import { ToastContainer, Slide } from "react-toastify";
import "react-virtualized/styles.css";
import "flatpickr/dist/themes/material_green.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-toastify/dist/ReactToastify.css";
import "@momentum-ui/core/scss/momentum-ui.scss";
import "index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthProvider } from "pages";
import Layout from "components/app/AppLayoutV2";
import { AnimatePresence, motion } from "framer-motion";
import { SWRConfig } from "swr";
import { useAxios } from "myAxios";
import { Cookies } from "utils";
import {
  CI_EMAILID_COOKIE,
  CI_FIRST_NAME_COOKIE,
  LAST_NAME_COOKIE,
  ROLE_COOKIE,
} from "constant";
import { ActivityLookupContextProvider } from "components/playbook/context/ActivityLookupContext";
import { TemplateIdContextProvider } from "components/playbook/context/TemplateIdContext";

const fetcher = (url: string, headers?: string, addPaging?: boolean) => {
  const axios = headers ? useAxios({ headers }) : useAxios();
  if (addPaging) {
    return axios.get(url).then((res) => ({
      data: res.data,
      "total-elements": +res.headers["total-elements"] ?? 0,
      "total-pages": +res.headers["total-pages"] ?? 0,
    }));
  }
  return axios.get(url).then((res) => res.data);
};

export const postFetcher = (url: string, body: string) => {
  const axios = useAxios();
  return axios.post(url, JSON.parse(body)).then((res) => res.data);
};

export default function App({ Component, pageProps, router }: AppProps) {
  LicenseManager.setLicenseKey(
    "CompanyName=XLsoft China_on_behalf_of_Cisco (China),LicensedApplication=CISCO,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-010766,ExpiryDate=25_September_2021_[v2]_MTYzMjUyNDQwMDAwMA==b5743f8de2ce37605e76a26b000940e6"
  );

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles!.parentElement!.removeChild(jssStyles);
    }

    //for matomo to track url
    router.events.on("routeChangeComplete", () => {
      const _paq = (window as any)._paq ?? [];
      _paq.push([
        "setCustomVariable",
        1,
        Cookies.get(CI_EMAILID_COOKIE) ?? "Unknown",
        `${Cookies.get(CI_FIRST_NAME_COOKIE)} ${Cookies.get(LAST_NAME_COOKIE)}`,
        "visit",
      ]);
      _paq.push([
        "setCustomVariable",
        2,
        "role",
        Cookies.get(ROLE_COOKIE) ?? "Unknown",
        "visit",
      ]);
      _paq.push(["setCustomUrl", `${location.pathname}`]);
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
    });
  }, []);

  const theme = useMemo(() => lightTheme, []);
  const isAssignPage = router.pathname === "/main/assignmentTool";

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
        fetcher,
      }}
    >
      <ThemeProvider theme={theme}>
        <AuthProvider.Provider value={pageProps.authDetail}>
          <ActivityLookupContextProvider>
            <TemplateIdContextProvider>
              <Head>
                <title>Cloud Collaboration Success Portal</title>
              </Head>
              <CssBaseline />
              <ToastContainer
                transition={Slide}
                closeButton={true}
                autoClose={2000}
              />
              <Layout>
                <AnimatePresence exitBeforeEnter={true} initial={false}>
                  <motion.div
                    key={router.asPath}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                      height: "100%",
                      borderRadius: 4,
                      margin: isAssignPage ? "-20px -24px auto -25px" : "auto",
                    }}
                    id={"content-wrapper"}
                  >
                    <Component {...pageProps} />
                  </motion.div>
                </AnimatePresence>
              </Layout>
            </TemplateIdContextProvider>
          </ActivityLookupContextProvider>
        </AuthProvider.Provider>
      </ThemeProvider>
    </SWRConfig>
  );
}
