import { ICellRendererParams } from "ag-grid-community";
import { FC } from "react";

interface CountryParams {
  type?: string;
}

const CountryRenderer: FC<Partial<ICellRendererParams> & CountryParams> = ({
  data,
  type,
}) => {
  let code = "";
  let name = "";
  if (type === "contact") {
    code = data?.contactCountry?.code;
    name = data?.contactCountry?.name;
  } else if (type === "searchInfo") {
    code = data?.code;
    name = data?.name;
  } else {
    code = data?.country?.code;
    name = data?.country?.name;
  }

  if (code) {
    return (
      <>
        <img
          style={{
            boxShadow: "0px 0px 4px #E7E7E7",
            border: "none",
            width: 15,
            height: 10,
          }}
          src={`https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/${code.toLocaleLowerCase()}.svg`}
        />
        <span> {name}</span>
      </>
    );
  }
  return <></>;
};

export default CountryRenderer;
