import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

export const TemplateIdContext = createContext<{
  templateId: string;
  setTemplateId: Dispatch<SetStateAction<string>>;
}>({
  templateId: "",
  setTemplateId: () => ({}),
});

export const TemplateIdContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [templateId, setTemplateId] = useState<string>("");
  return (
    <TemplateIdContext.Provider
      value={{
        templateId: templateId,
        setTemplateId: setTemplateId,
      }}
    >
      {children}
    </TemplateIdContext.Provider>
  );
};
