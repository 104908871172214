import React, { FC, useContext } from "react";
import { AppBar, Toolbar, makeStyles, IconButton } from "@material-ui/core";
import {
  EMAILID_COOKIE,
  FEATURE_KEY,
  FIRST_NAME_COOKIE,
  // LAST_NAME_COOKIE,
  MENU_DEFINITIONS,
  MOCK_USER_COOKIE,
  ROLE,
  TOKEN_COOKIE,
} from "constant";
import { Cookies } from "utils";
import MockUserPopper from "./MockUserPopper";
import { useAxios } from "myAxios";
import { AuthProvider } from "pages";
import dynamic from "next/dynamic";
import { AccountCircle, Menu } from "@material-ui/icons";
import AuthCheck from "./AuthCheck";
import ToggleControlPopper from "./ToggleControlPopper";
import AppLayoutContext from "components/context/AppLayoutContext";
import UnifiedSearchBar from "components/app/UnifiedSearchBar";
import { MenuItemDef } from "types/app";
import CiscoAvatar from "components/app/CiscoAvatar";
import AppHeaderNotification from "components/app/AppHeaderNotification";

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#343537",
    zIndex: theme.zIndex.drawer + 1,
  },
  menuAndLogoWrapper: {
    display: "flex",
  },
  userWrapper: {
    display: "flex",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: "2px solid white",
    borderRadius: "50%",
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center",
  },
  username: {
    marginLeft: theme.spacing(1),
  },
  iconContainer: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: "#00a0d1",
    },
  },
  logoutIconContainer: {
    transform: "rotate(180deg)",
  },
  logoutIcon: {
    verticalAlign: "middle",
  },
  menuIcon: {
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbarGutter: {
    padding: "0 18px",
  },
}));

const Header: FC = () => {
  const classes = useStyles();
  const mockEmail = Cookies.get(MOCK_USER_COOKIE);
  const firstName = Cookies.get(FIRST_NAME_COOKIE);
  // const lastName = Cookies.get(LAST_NAME_COOKIE);
  const email = Cookies.get(EMAILID_COOKIE);
  const [username] = (mockEmail || email || "@").split("@");
  const axios = useAxios();
  const authDetail = useContext(AuthProvider);
  const isPartner = authDetail?.role === ROLE.PARTNER_ROLE;
  const { open, setOpen } = useContext(AppLayoutContext);

  const onLogout = () => {
    Cookies.remove(TOKEN_COOKIE);
    Cookies.remove(MOCK_USER_COOKIE);
    Cookies.remove(EMAILID_COOKIE);
    postMessage("LOGOUT", "*");
    axios.get<{ url: string }>("/api/auth/logout").then(({ data }) => {
      window.location.assign(data.url);
    });
  };

  const onClick = () => {
    setOpen((prv) => !prv);
  };

  const def = MENU_DEFINITIONS.reduce(function cb(res, cur) {
    const result = (cur.children || []).reduce(cb, [] as MenuItemDef[]);
    if (result.length) {
      res.push(...result);
    } else if (cur.href === location.pathname) {
      res.push(cur);
    }
    return res;
  }, [] as MenuItemDef[]);

  const enableSearch =
    def.length && (def[0].enableSearch || def[0].summaryRoute);

  const hideIncludeInactive = Boolean(def.length && def[0].hideIncludeInactive);

  const isSummary = Boolean(def.length && def[0].summaryRoute);

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={1}>
      <Toolbar
        classes={{
          gutters: classes.toolbarGutter,
        }}
        className={classes.appBar}
        suppressHydrationWarning={true}
      >
        <div className={classes.menuAndLogoWrapper}>
          <IconButton
            size="small"
            style={{ marginRight: 24 }}
            onClick={onClick}
          >
            <Menu
              style={{
                color: "white",
                transform: open ? "rotate(90deg)" : "rotate(0deg)",
              }}
              className={classes.menuIcon}
            />
          </IconButton>
          <img src="/logo.svg" width={200} height={20} />
        </div>
        {enableSearch ? (
          <UnifiedSearchBar
            isSummary={isSummary}
            hideIncludeInactive={hideIncludeInactive}
          />
        ) : null}
        <div className={classes.avatarWrapper}>
          {isPartner ? (
            <AccountCircle />
          ) : (
            <CiscoAvatar
              className={classes.avatar}
              username={username}
              invert={true}
            />
          )}
          <div className={classes.username}>
            Hello {isPartner ? email : firstName}
          </div>
          {authDetail?.toggles?.WXCSENG_MOCK_USER_LOGIN ? (
            <MockUserPopper />
          ) : null}
          <AuthCheck id={FEATURE_KEY.APP_TOGGLE_CONTROL}>
            <ToggleControlPopper />
          </AuthCheck>
          <AppHeaderNotification />
          <div
            className={`${classes.logoutIconContainer} ${classes.iconContainer}`}
            onClick={onLogout}
          >
            <i
              className={`cui-icon icon icon-sign-out_20 ${classes.logoutIcon}`}
            />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default dynamic(() => Promise.resolve(Header), {
  ssr: false,
});
