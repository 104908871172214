import {
  makeStyles,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import React, {
  FC,
  useEffect,
  useState,
  forwardRef,
  useContext,
  ChangeEvent,
  useRef,
} from "react";
import { ChevronRight, Clear, Info, Search } from "@material-ui/icons";
import dynamic from "next/dynamic";
import {
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderGroupParams,
  Skeleton,
} from "@material-ui/lab";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { Tooltip } from "@material-ui/core";
import { useAccountBySearch } from "components/summary/hooks";
import { DetailAccount, MatchedType } from "types/summary";
import GridEventProvider from "components/context/GridEventContext";
import { GLOBAL_SEARCH_RECENT_KEY } from "constant";
import { getItemFromLocalStorage } from "utils";
import { GridApi } from "ag-grid-community";

import SearchDetailComponent from "./SearchDetailComponent";

const useStyles = makeStyles(() => ({
  divider: {
    height: 32,
    margin: "0 8px",
  },
  inputRoot: { flexWrap: "nowrap", paddingRight: "4px !important" },
  searchBar: {
    "& fieldset": {
      display: "none",
    },
  },
  option: {
    // fontSize: 14,
    // color: theme.palette.primary.main,
    color: "#333",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",
    textOverflow: "ellipsis",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  searchResultContainer: {
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "1px solid #E7E7E7",
    flexDirection: "column",
    padding: "8px 15px",
  },
  searchResult: {
    fontSize: 12,
    fontWeight: 700,
    color: "#343537",
  },
  searchResultHint: {
    fontSize: 10,
    fontWeight: 400,
    color: "#545454",
    display: "flex",
    alignItems: "end",
  },
  root: {
    width: 428,
  },
  paper: ({ showDetail, isEmbedded }: any) => ({
    width: "max-content",
    position: showDetail && isEmbedded ? "absolute" : "relative",
    left: showDetail && isEmbedded ? -428 : 0,
  }),
  popper: {
    zIndex: 1301,
  },

  labelWrapper: {
    background: "#B2B2B2",
    borderRadius: 5,
    padding: "0px 5px",
    marginLeft: 10,
  },

  labelTxt: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 600,
    color: "#fff",
    fontSize: "10px !important",
    whiteSpace: "nowrap",
  },
  noOptions: {
    padding: 0,
  },
  menuTitle: { color: "#898989", fontSize: 11, lineHeight: "15px" },
  controlRoot: { marginRight: -16 },
  tooltip: { maxHeight: "50vh" },
}));

interface SearchOption {
  name: string;
  value: string;
  subText?: string;
  type?: string;
  active: boolean;
  confidential: boolean;
  matchedType: MatchedType;
  subTextActive: boolean;
  groupName?: string;
}

let lastQuery: any;

interface UnifiedSearchBarProps {
  isSummary?: boolean;
  hideIncludeInactive?: boolean;
  darkMode?: boolean;
  embedded?: boolean;
}

// recent need to store string and option
export interface RecentOption extends SearchOption {
  isSearchOption: boolean;
  includeInactive: boolean;
}

// the minion keyword length
const MIN_CHAR_LIMIT = 2;

const UnifiedSearchBar: FC<UnifiedSearchBarProps> = ({
  isSummary = false,
  hideIncludeInactive = false,
  darkMode = true,
  embedded = false,
}) => {
  const { gridEvent } = useContext(GridEventProvider);
  // const authDetail = useContext(AuthProvider);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(() => "");
  const [value, setValue] = useState<SearchOption | null>(null);
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [query, setQuery] = useState("");
  const {
    data: accounts,
    isValidating,
    error: accountsError,
  } = useAccountBySearch(query);
  const [recent, setRecent] = useState<RecentOption[]>(() =>
    getItemFromLocalStorage(GLOBAL_SEARCH_RECENT_KEY, [] as RecentOption[])
  );
  const [fromRecent, setFromRecent] = useState(false);
  const [curAccountId, setCurAccountId] = useState("");
  const [curConfidential, setCurConfidential] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const router = useRouter();
  const isEmbedded =
    router.pathname === "/spng/playbook" ||
    router.pathname === "/spng/activity";
  const classes = useStyles({ showDetail, isEmbedded });
  const defaultMenuRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const detailRef = useRef<HTMLDivElement | null>(null);

  let pageName = "";
  const isESD = router.pathname === "/spng/dashboard/enterprise";
  const isHealthAssess = router.pathname === "/spng/dashboard/renewals";
  if (isESD || isHealthAssess) {
    pageName = isESD ? "ESD" : "healthAssessment";
  }

  useEffect(() => {
    if (gridEvent) {
      const filterModel = gridEvent.api.getFilterModel();
      const { externalKeyword, externalSearch } = filterModel;
      let keyword = "";
      let includeInactive = false;
      if (externalSearch) {
        const [include, ...keywords] = externalSearch?.filter?.split("%|%");
        includeInactive = include === "true";
        if (!keyword) {
          keyword = keywords.join("");
        }
      }
      if (externalKeyword) {
        keyword = externalKeyword.filter ?? "";
      }
      setIncludeInactive(includeInactive);
      setInputValue(keyword);
    }
  }, [gridEvent]);

  useEffect(() => {
    if (gridEvent) {
      gridEvent.api.addEventListener("filterChanged", (e: any) => {
        const api: GridApi = e.api;
        const { externalSearch, externalKeyword } = api.getFilterModel();
        if (externalSearch) {
          const [include, ...text] = externalSearch.filter.split("%|%");
          if (text.length === 1 && !text[0]) {
            setInputValue("");
          } else {
            setInputValue(externalKeyword?.filter ?? text.join(""));
          }
          setIncludeInactive(include === "true");
        } else {
          setInputValue("");
          setIncludeInactive(false);
        }
      });
    }
  }, [gridEvent]);

  useEffect(() => {
    const closePop = (e: Event) => {
      if (
        !defaultMenuRef.current?.contains(e.target as Node) &&
        defaultMenuRef.current !== e.target &&
        !inputRef.current?.contains(e.target as Node) &&
        !detailRef?.current?.contains(e.target as Node) &&
        detailRef?.current !== e.target
      ) {
        setOpen(false);
        setShowDetail(false);
      }
    };
    document.addEventListener("click", closePop);
    return () => {
      document.removeEventListener("click", closePop);
    };
  }, []);

  useEffect(() => {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) {
      lastQuery?.cancel?.();
      setOptions([]);
      setQuery("");
    }
    if (trimmedValue.length > MIN_CHAR_LIMIT) {
      setLoading(true);
      const debouncedSetQuery = debounce(() => {
        setQuery(
          `keyword=${
            trimmedValue ? encodeURIComponent(trimmedValue) : ""
          }&includeInactive=${includeInactive}${
            pageName ? `&pageName=${pageName}` : ""
          }`
        );
      }, 500);
      lastQuery?.cancel?.();
      lastQuery = debouncedSetQuery;
      debouncedSetQuery();
      if (fromRecent) {
        setOpen(false);
        setFromRecent(false);
      }
    }
  }, [inputValue, includeInactive, fromRecent]);

  useEffect(() => {
    if (accounts && !isValidating) {
      const options = generateOption(accounts);
      setOptions(options);
      setLoading(false);
    }
  }, [accounts, isValidating]);

  useEffect(() => {
    if (accountsError) {
      setLoading(false);
    }
  }, [accountsError]);

  useEffect(() => {
    if (value) {
      if (isSummary) {
        router.push({
          pathname: value.confidential
            ? router.pathname.includes("/usage/teams") ||
              router.pathname.includes("/usage/calling")
              ? "/spng/summary"
              : router.pathname
            : router.pathname,

          query: {
            accountId: value.value,
          },
        });
      } else {
        gridSubmit(value.value, includeInactive, false, value.name);
      }
    } else {
      const trimmedInput = inputValue.trim();
      if (trimmedInput.length > MIN_CHAR_LIMIT) {
        gridSubmit(trimmedInput, includeInactive, false);
      }
    }
  }, [value, isSummary, includeInactive]);

  const onInputChange = (
    _e: any,
    input: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason !== "reset") {
      setInputValue(input);
    }
  };

  const onOpen = () => {
    setOpen(true);
  };

  const clear = () => {
    setInputValue("");
    setValue(null);
    const filterModel = gridEvent?.api.getFilterModel();
    gridEvent?.api.setFilterModel({
      ...filterModel,
      externalSearch: {
        type: "contains",
        filter: `${includeInactive}%|%`,
      },
      externalKeyword: {
        type: "contains",
        filter: "",
      },
    });
  };

  const onClose = (e: ChangeEvent<any>, reason: AutocompleteCloseReason) => {
    if (reason !== "blur") {
      setOpen(false);
    }
  };

  const handleRecent = (option: RecentOption) => {
    const rest = recent.filter((x) => x.value !== option.value);
    const result = [option, ...rest];
    if (result.length > 5) {
      result.pop();
    }
    setRecent(result);
    localStorage.setItem(GLOBAL_SEARCH_RECENT_KEY, JSON.stringify(result));
  };

  const gridSubmit = (
    keyword: string,
    includeInactive: boolean,
    setToMyAccounts = false,
    highlightKeyword?: string
  ) => {
    let filterModel = gridEvent?.api.getFilterModel();
    if (setToMyAccounts) {
      filterModel = {
        ...filterModel,
        externalFilter: { filter: "My Accounts", type: "contains" },
      };
    }
    gridEvent?.api.setFilterModel({
      ...filterModel,
      externalSearch: {
        type: "contains",
        filter: `${includeInactive}%|%${keyword}`,
      },
      externalKeyword: {
        type: "contains",
        filter: highlightKeyword ?? keyword,
      },
    });
  };

  const handleOptionClicked = (value: SearchOption | null) => {
    setValue(value);
    if (value) {
      setInputValue(value.name);
      handleRecent({
        ...value,
        isSearchOption: true,
        includeInactive,
      });
    }
  };

  /**
   * @desc called when user submit the search
   */
  const handleSubmit = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue) {
      handleRecent({
        isSearchOption: false,
        value: trimmedValue,
        name: trimmedValue,
        active: true,
        confidential: false,
        includeInactive,
        matchedType: "STARTS",
        subTextActive: true,
      });
      // setRecent((prv) => [value, ...prv]);
      gridSubmit(trimmedValue, includeInactive);
      setOpen(false);
      setValue(null);
    }
  };

  const clearRecent = () => {
    setRecent([]);
    localStorage.removeItem(GLOBAL_SEARCH_RECENT_KEY);
  };

  const generateOption = (accounts?: DetailAccount[]): SearchOption[] => {
    if (accounts?.length) {
      return accounts.slice(0, 8).map((x) => {
        let subText = "";
        let type = "";
        if (x?.items?.[0].matchedField !== "ACCOUNT_NAME") {
          subText = x?.items?.[0]?.matchedValue;
          type = x?.items?.[0]?.matchedField;
        } else {
          subText = x?.items?.[1]?.matchedValue;
          type = x?.items?.[1]?.matchedField;
        }

        if (!subText && !type) {
          subText = x?.accountId;
          type = "ACCOUNT_ID";
        }
        return {
          name: x.accountName,
          value: x.accountId,
          subText,
          type,
          active: x.accountStatus === "Active",
          confidential: x.confidential,
          matchedType: x.items?.[0].matchedType ?? "STARTS",
          subTextActive: !!x.items?.[0].matchedValueStatus,
          groupName: x.assignedOrTagged ? "Most Related Account(s)" : "Other",
        };
      });
    }
    return [];
  };

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // stop the popup to close
    e.stopPropagation();
    setIncludeInactive((prv) => {
      // let user close the toggle
      if (prv && !inputValue) {
        gridSubmit(inputValue, false, false);
      }
      return !prv;
    });
  };

  const handleRecentOption = (option: RecentOption) => () => {
    setFromRecent(true);
    setInputValue(option.name ?? "");
    if (option.isSearchOption) {
      setOptions([option]);
      setValue(option);
    } else {
      gridSubmit(option.name, option.includeInactive);
    }
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key} disableSticky={true}>
      {params.group}
    </ListSubheader>,
    <div key={`${params.key}_children`} style={{ marginLeft: 16 }}>
      {params.children}
    </div>,
  ];

  const menuHeader = (
    <>
      <div style={{ padding: "16px 16px 0 16px", width: 428 }}>
        <p className={classes.menuTitle}>RESULT SETTING</p>
        <FormControlLabel
          control={
            <Switch
              checked={includeInactive}
              onChange={handleToggleChange}
              classes={{ root: classes.controlRoot }}
            />
          }
          label={`Include Inactive Accounts${
            hideIncludeInactive ? " (Not applicable on this page)" : ""
          }`}
          labelPlacement={"start"}
          disabled={hideIncludeInactive}
          style={{
            margin: 0,
            color: "#333333",
            fontSize: 13,
            lineHeight: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </div>
      <Divider />
    </>
  );

  const defaultMenu = (
    <div ref={defaultMenuRef}>
      {menuHeader}
      <div>
        {loading ? (
          Array.from({ length: 5 }).map((_x, i) => (
            <Skeleton
              key={i}
              variant={"rect"}
              height={28}
              style={{ margin: 12 }}
            />
          ))
        ) : accounts?.length === 0 ? (
          <p style={{ margin: "16px 0 16px 16px" }}>
            No accounts found by <b>{inputValue}</b>
          </p>
        ) : (
          <div style={{ paddingBottom: 16 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 16,
                paddingBottom: 0,
              }}
            >
              <p className={classes.menuTitle}>RECENTLY SEARCH</p>
              <span
                style={{
                  color: "#007AA3",
                  fontSize: 12,
                  lineHeight: "13.8px",
                  cursor: "pointer",
                }}
                onClick={clearRecent}
              >
                Clear
              </span>
            </div>

            <List disablePadding={true}>
              {recent.length ? (
                recent.map((r) => {
                  return (
                    <ListItem
                      disableGutters={true}
                      alignItems={"center"}
                      key={`${r.value}${r.subText}`}
                      style={{ paddingLeft: 12 }}
                      button={true}
                      onClick={handleRecentOption(r)}
                    >
                      <Search style={{ color: "#666666", marginRight: 8 }} />
                      <ListItemText
                        primary={r.name}
                        style={{ color: "#333333" }}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <p style={{ margin: "8px 16px" }}>
                  Your recent search will be saved
                </p>
              )}
            </List>
          </div>
        )}
      </div>
    </div>
  );

  const ListboxComponent = forwardRef<HTMLDivElement>(({ children }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          display: "flex",
          flexDirection: showDetail && isEmbedded ? "row-reverse" : "row",
        }}
      >
        <div
          style={{
            width: 428,
            paddingBottom: 16,
            borderRight: "1px solid #DEDEDE",
          }}
        >
          {menuHeader}
          {children}
        </div>
        <div ref={detailRef}>
          <SearchDetailComponent
            showDetail={showDetail}
            curAccountId={curAccountId}
            curConfidential={curConfidential}
          />
        </div>
      </div>
    );
  });

  const handleHoverOption = (option: any) => () => {
    setShowDetail(true);
    setCurAccountId(option.value);
    setCurConfidential(option.confidential);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", zIndex: 1301 }}>
      <Autocomplete
        classes={{
          option: classes.option,
          inputRoot: classes.inputRoot,
          root: classes.root,
          noOptions: classes.noOptions,
          paper: classes.paper,
          popper: classes.popper,
        }}
        style={{
          border: darkMode ? "unset" : "1px solid #DEDEDE",
          borderRadius: darkMode ? "unset" : 16,
        }}
        options={options}
        onChange={(_e, value) => {
          handleOptionClicked(value);
        }}
        renderGroup={renderGroup}
        groupBy={(o) => o.groupName || "Other"}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.value === value.value}
        size="small"
        clearOnBlur={false}
        blurOnSelect={false}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onClose={onClose}
        open={open}
        disableListWrap={true}
        disableCloseOnSelect={true}
        // loading={loading}
        noOptionsText={defaultMenu}
        onOpen={onOpen}
        selectOnFocus={false}
        filterOptions={(options) => options}
        ListboxComponent={ListboxComponent as any}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            size="small"
            placeholder="Search by Account Name, Account ID, Contract ID, CAV ID, CAV Name, CAV BU ID, CAV BU Name, Offer Code, Org Name, Site Name, SKU Desc, SKU ID, Sub ID, Web Order ID, Contact Email"
            classes={{ root: classes.searchBar }}
            onKeyUp={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            InputProps={{
              ...params.InputProps,
              style: darkMode
                ? {
                    height: 32,
                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                    caretColor: "white",
                    lineHeight: "16px",
                    color: "white",
                    fontSize: 14,
                  }
                : {
                    height: embedded ? 32 : 16,
                    backgroundColor: "white",
                    lineHeight: "16px",
                    fontSize: 13,
                    marginLeft: 4,
                    width: "98%",
                  },
              inputRef,
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    arrow={true}
                    interactive={true}
                    title={
                      <div>
                        <b>Enterprise</b>
                        <ul style={{ whiteSpace: "nowrap" }}>
                          <li>Account Name</li>
                          <li>Account ID</li>
                          <li>CAV ID</li>
                          <li>CAV Name</li>
                          <li>CAV BU ID</li>
                          <li>CAV BU Name</li>
                          <li>Contract ID</li>
                          <li>Offer Code</li>
                          <li>Organization Name</li>
                          <li>Site Name</li>
                          <li>SKU Description</li>
                          <li>SKU ID</li>
                          <li>Subscription ID</li>
                          <li>Web Order ID</li>
                          <li>Contact Email</li>
                        </ul>
                        <b>Online</b>
                        <ul>
                          <li>Email</li>
                        </ul>
                      </div>
                    }
                  >
                    <Info
                      color="primary"
                      style={{
                        transform: "translate(0px, -2px)",
                        color: "#00a0d1",
                        zIndex: 1000,
                        fontSize: "0.75rem",
                        verticalAlign: "center",
                        alignSelf: "center",
                        marginTop: 4,
                      }}
                    />
                  </Tooltip>
                </>
              ),
              endAdornment: (
                <>
                  {inputValue ? (
                    <IconButton size="small" onClick={clear}>
                      <Clear
                        style={{ color: darkMode ? "white" : "inherit" }}
                        fontSize="small"
                      />
                    </IconButton>
                  ) : null}
                  <IconButton size={"small"} onClick={handleSubmit}>
                    <Search style={{ color: darkMode ? "white" : "inherit" }} />
                  </IconButton>
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        renderOption={(option: SearchOption, { inputValue }) => {
          const regex = new RegExp(
            inputValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
            option.matchedType === "CONTAINS" ? "ig" : "i"
          );
          const spanText = option.name.replace(
            regex,
            `<span style="background-color:#FFE399; font-size: 14px">$&</span>`
          );
          const subSpanText = option.subText
            ? option.subText?.replace(
                regex,
                `<span style="background-color:#FFE399;font-size: 12px">$&</span>`
              )
            : "";
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                onMouseEnter={handleHoverOption(option)}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {!option.active ? (
                      <div
                        style={{
                          color: "#A12512",
                          backgroundColor: "#FFE8E3",
                          padding: "2px 4px",
                          borderRadius: 4,
                          fontSize: 10,
                          marginRight: 4,
                        }}
                      >
                        Inactive
                      </div>
                    ) : null}
                    <div
                      style={{
                        width: 320,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#333",
                      }}
                      dangerouslySetInnerHTML={{ __html: spanText }}
                    />
                  </div>
                  {subSpanText ? (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {option.active && !option.subTextActive ? (
                        <div
                          style={{
                            color: "#A12512",
                            backgroundColor: "#FFE8E3",
                            padding: "2px 4px",
                            borderRadius: 4,
                            fontSize: 10,
                            marginRight: 4,
                          }}
                        >
                          Inactive
                        </div>
                      ) : null}
                      <div
                        style={{
                          maxWidth: 300,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: "#898989",
                          fontSize: 12,
                        }}
                        dangerouslySetInnerHTML={{ __html: subSpanText }}
                      />
                      <div className={classes.labelWrapper}>
                        <span className={classes.labelTxt}>{option.type}</span>
                      </div>
                    </div>
                  ) : null}
                </div>
                <ChevronRight fontSize="small" />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default dynamic(() => Promise.resolve(UnifiedSearchBar), {
  ssr: false,
  loading: () => (
    <Skeleton
      variant="rect"
      width={600}
      height={30}
      style={{ paddingRight: 23 }}
    />
  ),
});
