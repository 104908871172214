import {
  AlertDetail,
  AlertIconType,
} from "components/portfolio/components/alert/alert-icon-type";
import useSWR from "swr";
import {
  Account,
  AlertOverview,
  BppData,
  BppDetail,
  CallingPsmMetadata,
  CCSEMetadata,
  CIOrgDetail,
  Counts,
  ExecutiveOverview,
  InvoiceChangeDetail,
} from "types/portfolio";

export const useCounts = () =>
  useSWR<Counts>("/api/dataservice/v1/accounts/overview");

export const useAlertOverview = () =>
  useSWR<AlertOverview>("/api/dataservice/v1/alerts/overview");

export const useExecutiveOverview = (topCategory: string) =>
  useSWR<ExecutiveOverview>(
    `/api/dataservice/v1/accounts/executive-healths/overview?type=${topCategory}`
  );

export const useRevenueChange = (accountKey: string) =>
  useSWR<InvoiceChangeDetail[]>(
    accountKey ? `/api/dataservice/v1/accounts/${accountKey}/mrr-change` : null
  );

export const useCCSE = (fetch = true) =>
  useSWR<Array<CCSEMetadata>>(fetch ? "/api/portfolio/metadata/ccse" : null);

export const useCallingEpsm = (fetch = true) =>
  useSWR<Array<CallingPsmMetadata>>(
    fetch ? "/api/portfolio/metadata/calling-epsm" : null
  );

export const useCallingPsm = (fetch = true) =>
  useSWR<Array<CallingPsmMetadata>>(
    fetch ? "/api/portfolio/metadata/calling-psm" : null
  );

export const useAlertsByAccountKeyAndType = (
  accountKey?: string,
  type?: AlertIconType
) =>
  useSWR<AlertDetail[]>(
    accountKey
      ? `/api/dataservice/v1/accounts/${accountKey}/alerts${
          type ? `?alertType=${type}&sort=createdDate:desc` : ""
        }`
      : null
  );

export const useOrgInvoiceArr = (options: {
  anchorEl: any;
  viewType: string;
  organizationUnitId: string;
  includeInactive: number;
  sort: string;
  pageNum: number;
  pageSize: number;
}) => {
  const params: string[] = [];
  params.push(`viewType=${options.viewType}`);
  params.push(
    `organizationUnitId=${encodeURIComponent(options.organizationUnitId)}`
  );
  params.push(`sort=${options.sort}`);
  if (options.includeInactive) {
    params.push(`includeInactive=${options.includeInactive}`);
  }
  return useSWR<Account[]>(
    options.anchorEl
      ? [
          `/api/dataservice/v1/accounts/smart-search?${params.join("&")}`,
          "page-number:0;page-size:500",
        ]
      : null
  );
};

export const useBppById = (accountId: string) =>
  useSWR<BppData>(
    accountId ? `/api/dataservice/v1/accounts/${accountId}/bpp` : null
  );

export const useBppDetailByKey = (contactKey: string) =>
  useSWR<BppDetail[]>(
    contactKey ? `/api/dataservice/v1/contacts/${contactKey}/bpp` : null
  );

export const useDetailByKey = (accountKey: string) =>
  useSWR<CIOrgDetail[]>(
    accountKey
      ? `/api/dataservice/v1/accounts/${accountKey}/ci-organizations`
      : null
  );
