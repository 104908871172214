import React, { FC, useEffect, useState } from "react";
import AppHeader from "./AppHeaderV2";
import Banner from "./Banner";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "./AppMenuV2";
import AppLayoutContext from "components/context/AppLayoutContext";
import GridEventProvider from "components/context/GridEventContext";
import { GridReadyEvent } from "ag-grid-community";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: (props: any) => props.drawerWidth,
    paddingTop: 84,
    filter: "drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.1))",
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerOpen: {
    transition: theme.transitions.create(["width", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create(["width", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const Layout: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [hoverOpen, setHoverOpen] = useState(false);
  const [gridEvent, setGridEvent] = useState<undefined | GridReadyEvent>(
    undefined
  );
  const [isCiscoNetwork, setIsCiscoNetwork] = useState(true);

  useEffect(() => {
    /* use to detect if is cisco network */
    /* add workaround because event is not fired during hydrate */
    /* sometime QA server is in maintain, the default image timeout 20+seconds is too long*/
    /* user avator can only be displayed in intranet, so PRODUCT avator will not display when QA is down!*/
    const img = new Image();
    img.src = "https://success.qa.webex.com/logo.svg";
    const timer = setTimeout(() => {
      if (img.complete) {
        setIsCiscoNetwork(true);
        return;
      }
      setIsCiscoNetwork(false);
      img.src = "";
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const drawerWidth = open || hoverOpen ? 240 : 60;

  const classes = useStyles({
    drawerWidth,
  });
  const router = useRouter();
  const [height, setHeight] = useState(
    router.pathname.startsWith("/main") ? "100vh" : "auto"
  );

  useEffect(() => {
    setHeight(router.pathname.startsWith("/main") ? "100vh" : "auto");
  }, [router.pathname]);

  useEffect(() => {
    const handleEvent = (e: any) => {
      const { detail: height } = e;
      setHeight(height ? `${height + 60}px` : "100vh");
    };
    addEventListener("IFRAME_HEIGHT", handleEvent);
    return () => {
      removeEventListener("IFRAME_HEIGHT", handleEvent);
    };
  }, []);

  const onMouseEnter = () => {
    if (!hoverOpen) setHoverOpen(true);
  };

  const onMouseLeave = () => {
    if (hoverOpen) setHoverOpen(false);
  };

  return (
    <GridEventProvider.Provider
      value={{
        gridEvent,
        setGridEvent,
      }}
    >
      <AppLayoutContext.Provider
        value={{ open, setOpen, hoverOpen, isCiscoNetwork, setIsCiscoNetwork }}
      >
        <AppHeader />

        <Drawer
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          variant={"permanent"}
          open={true}
          style={{
            width: drawerWidth,
            transition: "width .5s ease-in-out",
          }}
          classes={{
            paper: `${classes.drawerPaper} ${
              open || hoverOpen ? classes.drawerOpen : classes.drawerClose
            }`,
          }}
          id={"app-menu"}
        >
          <Menu />
        </Drawer>
        <div
          style={{
            height,
            paddingTop: 84,
            paddingLeft: (hoverOpen && !open ? 60 : drawerWidth) + 24,
            paddingRight: 24,
            paddingBottom: 24,
          }}
          className={open ? classes.drawerOpen : classes.drawerClose}
        >
          <>
            <Banner />
            {children}
          </>
        </div>
      </AppLayoutContext.Provider>
    </GridEventProvider.Provider>
  );
};

export default Layout;
