import {
  FormControlLabel,
  List,
  ListItem,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { FC, MouseEvent, useState, useContext, ChangeEvent } from "react";
import ArrowPopper from "./ArrowPopper";
import { Cookies } from "utils";
import { UUID_COOKIE, FEATURE_TOGGLES } from "constant";
import { AuthProvider } from "pages";
import { useAxios } from "myAxios";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: "#00a0d1",
    },
  },
  logoutIcon: {
    verticalAlign: "middle",
  },
  buttonRow: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface Option {
  id: string;
  name: string;
  value: boolean;
}

const ToggleControlPopper: FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const authdetail = useContext(AuthProvider);

  const options: Option[] = [];
  Object.entries(FEATURE_TOGGLES).forEach((k) => {
    options.push({
      name: k[0],
      id: k[1],
      value: authdetail.toggles?.[k[1]] ?? false,
    });
  });

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  return (
    <>
      <div className={classes.iconContainer} onClick={onClick}>
        <i className={`cui-icon icon icon-adjust_20 ${classes.logoutIcon}`} />
      </div>
      <ArrowPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleClose}
        style={{
          maxHeight: "40vh",
          overflowY: "auto",
          display: !!anchorEl ? "block" : "none",
        }}
        keepMounted={true}
      >
        <div>
          <h4>Available Toggles:</h4>
          <List>
            {options.map((option) => (
              <ListItem key={option.name}>
                <Toggle option={option} />
              </ListItem>
            ))}
          </List>
        </div>
      </ArrowPopper>
    </>
  );
};

interface ToggleProps {
  option: Option;
}

const Toggle: FC<ToggleProps> = ({ option }) => {
  const axios = useAxios();
  const uuid = Cookies.get(UUID_COOKIE);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(option.value);
  const handleSwitch = (option: Option) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const val = e.target.checked;
    axios
      .post(`/api/features/users/${uuid}/developer`, {
        key: option.id,
        val: `${val}`,
      })
      .then(() => {
        setChecked(val);
        // to inform dataservice clean cache
        axios.put(`/api/dataservice/v1/people/settings/toggle/${option.id}`);
      })
      .catch((e) => {
        toast.error(e?.response?.data ?? "Set toggle fail", {
          hideProgressBar: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <FormControlLabel
      value="start"
      label={option.name}
      control={
        <Switch
          color="primary"
          checked={checked}
          onChange={handleSwitch(option)}
          disabled={loading}
        />
      }
    />
  );
};

export default ToggleControlPopper;
