import { TypeOption } from "components/app/BaseMultipleAutocomplete/BaseMultipleAutocomplete";
import { useMetadataByCategory } from "components/summary/hooks";
import { createContext, ReactNode } from "react";

export const ActivityLookupContext = createContext<{
  typeOptions: TypeOption[];
  productOptions: TypeOption[];
  purposeOptions: TypeOption[];
}>({
  typeOptions: [],
  productOptions: [],
  purposeOptions: [],
});

export const ActivityLookupContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data: typeLookup } = useMetadataByCategory("ACTIVITY_TYPE");
  const { data: productLookup } = useMetadataByCategory("ACTIVITY_PRODUCT");
  const { data: purposeLookup } = useMetadataByCategory("ACTIVITY_PURPOSE");

  return (
    <ActivityLookupContext.Provider
      value={{
        typeOptions:
          typeLookup?.map((d) => ({
            name: d.code,
            value: d.value,
            type: d.category,
          })) ?? [],
        productOptions:
          productLookup?.map((d) => ({
            name: d.code,
            value: d.value,
            type: d.category,
          })) ?? [],
        purposeOptions:
          purposeLookup?.map((d) => ({
            name: d.code,
            value: d.value,
            type: d.category,
          })) ?? [],
      }}
    >
      {children}
    </ActivityLookupContext.Provider>
  );
};
