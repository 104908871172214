import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  TextFieldProps,
  Typography,
} from "@material-ui/core";
import { FC, MouseEvent, useRef, useState, KeyboardEvent } from "react";
import ArrowPopper from "./ArrowPopper";
import { Cookies } from "utils";
import {
  CI_EMAILID_COOKIE,
  EMAILID_COOKIE,
  FIRST_NAME_COOKIE,
  MOCK_USER_COOKIE,
  CI_FIRST_NAME_COOKIE,
} from "constant";
import MButton from "components/momentum/MButton";
import useSWR from "swr";
import { Person } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: "#00a0d1",
    },
  },
  logoutIcon: {
    verticalAlign: "middle",
  },
  buttonRow: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface TestUser {
  userId: string;
  displayName: string;
}

interface TestUserObject {
  debug: boolean;
  users: TestUser[];
}

const MockUserPopper: FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [message, setMessage] = useState("");
  const { data } = useSWR<TestUserObject>(
    "/api/dataservice/api/auth/v2/test-users"
  );
  const mockerEmail = Cookies.get(MOCK_USER_COOKIE);
  const inputRef = useRef<TextFieldProps>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };
  const handleValue = (value: string) => {
    const regex = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/g;
    if (value && regex.test(value)) {
      const [username, _domain] = value.split("@");
      Cookies.set(MOCK_USER_COOKIE, `"${value}"`);
      Cookies.set(FIRST_NAME_COOKIE, `"${username}"`);
      Cookies.set(EMAILID_COOKIE, `"${value}"`);
      window.location.reload();
    } else {
      setMessage("Please enter valid email");
    }
  };
  const onApply = () => {
    const value = inputRef.current?.value as string;
    handleValue(value);
  };
  const onRevert = () => {
    Cookies.remove(MOCK_USER_COOKIE);
    const userEmail = Cookies.get(CI_EMAILID_COOKIE);
    const userName = Cookies.get(CI_FIRST_NAME_COOKIE);
    if (userEmail && userName) {
      Cookies.set(EMAILID_COOKIE, `"${userEmail}"`);
      Cookies.set(FIRST_NAME_COOKIE, `"${userName}"`);
      window.location.reload();
    } else {
      window.location.assign("/spng");
    }
  };
  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onApply();
    }
  };
  const handleListClick = (value: string) => () => {
    handleValue(value);
  };

  return (
    <>
      <div className={classes.iconContainer} onClick={onClick}>
        <i
          className={`cui-icon icon icon-assign-privilege_20 ${classes.logoutIcon}`}
        />
      </div>
      <ArrowPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleClose}
        style={{ maxHeight: "40vh", overflowY: "auto" }}
      >
        <div>
          {data?.debug ? (
            <TextField
              variant="outlined"
              defaultValue={mockerEmail}
              size="small"
              placeholder="Enter Mock User Email"
              inputRef={inputRef}
              error={!!message}
              helperText={message}
              fullWidth={true}
              onKeyPress={handleEnter}
            />
          ) : null}

          <div style={{ marginTop: 8 }}>
            <div className={classes.buttonRow}>
              <Typography variant="subtitle2">Available test users:</Typography>
              <MButton onClick={onRevert} buttonType="blue">
                Revert
              </MButton>
            </div>
            {data?.users.length ? (
              <List>
                {data.users.map((user) => (
                  <ListItem
                    button={true}
                    key={user.userId}
                    onClick={handleListClick(user.userId)}
                    selected={mockerEmail === user.userId}
                  >
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={user.displayName}
                      color="primary"
                      secondary={user.userId}
                    />
                  </ListItem>
                ))}
              </List>
            ) : null}
          </div>
        </div>
      </ArrowPopper>
    </>
  );
};

export default MockUserPopper;
