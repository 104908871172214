import axios from "axios";
import {
  EMAILID_COOKIE,
  IS_DEV,
  IS_SERVER,
  LAST_URL_COOKIE,
  MOCK_USER_COOKIE,
  PATH_PREFIX,
  TOKEN_COOKIE,
  UUID_COOKIE,
} from "constant/index";
import { Agent } from "https";
import { toast } from "react-toastify";
import { NextApiRequest } from "next";
import { getCookieByKey, Cookies } from "utils";
import { IncomingMessage } from "http";

interface MyAxiosProps {
  token?: string;
  emailId?: string;
  uuid?: string;
  headers?: string;
  req?: NextApiRequest | IncomingMessage;
  authorization?: string;
}

type AxiosType = "csdigital" | "dataService" | "featureService";

const typeToKeyMapping: Record<AxiosType, string> = {
  csdigital: "CS_DIGITAL_BASE_URL",
  dataService: "DATA_SERVICE_BASE_URL",
  featureService: "FEATURE_SERVICE_BASE_URL",
};

export const useAxios = (
  props?: MyAxiosProps,
  type: "csdigital" | "dataService" | "featureService" = "csdigital",
  needBaseUrl = true
) => {
  const cookie = props?.req?.headers.cookie;
  const token = IS_SERVER
    ? getCookieByKey(TOKEN_COOKIE, cookie)
    : Cookies.get(TOKEN_COOKIE);
  const emailId = IS_SERVER
    ? getCookieByKey(EMAILID_COOKIE, cookie)?.replace(/"/g, "")
    : Cookies.get(EMAILID_COOKIE);
  const uuid = IS_SERVER
    ? getCookieByKey(UUID_COOKIE, cookie)
    : Cookies.get(UUID_COOKIE);
  const mockEmailId = IS_SERVER
    ? props?.req?.headers?.user
      ? props?.req?.headers?.user
      : getCookieByKey(MOCK_USER_COOKIE, cookie)?.replace(/"/g, "")
    : Cookies.get(MOCK_USER_COOKIE) ?? "";
  const baseURL = IS_SERVER
    ? process.env[typeToKeyMapping[type]]
    : IS_DEV || !needBaseUrl
    ? ""
    : PATH_PREFIX;
  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: props?.authorization
        ? props.authorization
        : `Bearer ${token}`,
      emailId: `${emailId}`,
      uuid: `${uuid}`,
      "Accept-Language": "en-US",
    },
    httpsAgent: new Agent({
      rejectUnauthorized: false,
    }),
    paramsSerializer: (params) => {
      const searchParams = new URLSearchParams();
      for (const key of Object.keys(params)) {
        const param = params[key];
        if (param === undefined) continue;

        if (Array.isArray(param)) {
          for (const p of param) {
            searchParams.append(key, p);
          }
        } else {
          searchParams.append(key, param);
        }
      }
      return searchParams.toString();
    },
  });
  instance.defaults.timeout = 60 * 20 * 1000;
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const { response } = err;
      if (response) {
        if (response.status === 401 && !IS_SERVER) {
          Cookies.set(LAST_URL_COOKIE, location.pathname + location.search);
          toast.error("Sorry, your session has expired!", {
            autoClose: false,
            position: "top-center",
            toastId: "expired",
          });
          setTimeout(() => {
            window.location.assign("/spng");
          }, 2000);
        }
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  );
  instance.interceptors.request.use((config) => {
    config.headers["user"] = mockEmailId;
    if (type === "csdigital" && IS_SERVER && !!mockEmailId) {
      config.params = { user: mockEmailId };
    }
    if (props?.headers) {
      props.headers.split(";").forEach((head) => {
        if (head) {
          const [k, v] = head.split(":");
          config.headers[k] = v;
        }
      });
    }

    return config;
  });
  return instance;
};
