import { Badge, Button, makeStyles, Typography } from "@material-ui/core";
import { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import ArrowPopper from "./ArrowPopper";
import useSWR from "swr";
import { BannerDataType } from "types/portfolio";
import { useLocalStorageState } from "ahooks";
import { DISMISSED_BANNER_KEY } from "constant";
import { Alert } from "@material-ui/lab";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: "#00a0d1",
    },
  },
  logoutIcon: {
    verticalAlign: "middle",
  },
  buttonRow: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
  bannerContainer: {
    "& > *": {
      marginTop: "16px",
    },
    "& > *:first-child": {
      marginTop: 0,
    },
  },
  icon: {
    alignItems: "center",
  },
  root: {
    justifyContent: "space-between",
  },
  action: {
    marginLeft: 0,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const excludePages = ["login", "spng", ""];

const AppHeaderNotification: FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [dismissed, setDismissed] = useLocalStorageState<string[]>(
    DISMISSED_BANNER_KEY,
    { defaultValue: [] }
  );
  const pageArray = /(?!.*\/)([^?#]+)/g.exec(router.asPath);
  const page = pageArray ? pageArray![1] : "";
  const { data, error } = useSWR<BannerDataType[]>(
    excludePages.includes(page) ? null : `/api/dataservice/v1/banners?page=all`
  );

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const dismiss = (id: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDismissed((prv) => (prv ? [...prv, id] : []));
  };

  //in order to prevent the cache group too fast, every time clean notifications that got disabled
  useEffect(() => {
    if (data) {
      setDismissed((prv) =>
        prv ? prv.filter((x) => data.find((y) => y.id === x)) : []
      );
    }
  }, [data, setDismissed]);

  const dismissAll = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (data) {
        setDismissed(data.map((x) => x.id));
      }
    },
    [data]
  );

  const count = data?.filter((x) => !dismissed.includes(x.id))?.length || 0;

  return (
    <>
      <div className={classes.iconContainer} onClick={onClick}>
        <Badge badgeContent={count} color={"error"}>
          <i className={`cui-icon icon icon-alert_20 ${classes.logoutIcon}`} />
        </Badge>
      </div>
      <ArrowPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleClose}
        style={{ maxHeight: "60vh", overflowY: "auto" }}
      >
        <div style={{ maxWidth: "30vw" }} className={classes.bannerContainer}>
          <div className={classes.title}>
            <Typography variant={"subtitle1"}>
              Notifications ({data?.length})
            </Typography>
            <Button color={"primary"} onClick={dismissAll}>
              Mark all as read
            </Button>
          </div>
          {error ? (
            <p>Failed to load notifications</p>
          ) : data === undefined ? (
            <p>Loading...</p>
          ) : data.length === 0 ? (
            <p>There are no notifications at the moment</p>
          ) : (
            data.map((bannerData) => {
              const hasDismissed = dismissed.includes(bannerData.id);
              return (
                <Badge
                  key={bannerData.id}
                  variant={"dot"}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  badgeContent={" "}
                  color={"error"}
                  invisible={hasDismissed}
                >
                  <Alert
                    severity={bannerData?.bannerType ?? "info"}
                    variant={"outlined"}
                    action={
                      !hasDismissed && (
                        <Button
                          color={"primary"}
                          onClick={dismiss(bannerData.id)}
                        >
                          read
                        </Button>
                      )
                    }
                    elevation={0}
                    classes={{
                      icon: classes.icon,
                      root: classes.root,
                      action: classes.action,
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: bannerData?.message ?? "",
                      }}
                    />
                  </Alert>
                </Badge>
              );
            })
          )}
        </div>
      </ArrowPopper>
    </>
  );
};

export default AppHeaderNotification;
