import { FC, useState } from "react";
import { makeStyles, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { BannerDataType } from "types/portfolio";
import useSWR from "swr";
import { useRouter } from "next/router";

const useStyles = makeStyles(() => ({
  bannerContainer: {
    textAlign: "center",
    fontSize: 16,
  },
  icon: {
    alignItems: "center",
  },
  root: {
    justifyContent: "space-between",
  },
  action: {
    marginLeft: 0,
  },
  container: {
    "&> *": {
      margin: "8px 0",
    },
  },
}));

const excludePages = ["login", "spng", ""];

const BannerContainer: FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const pageArray = /(?!.*\/)([^?#]+)/g.exec(router.asPath);
  const page = pageArray ? pageArray![1] : "";
  const { data: bannerData } = useSWR<BannerDataType[]>(
    excludePages.some((p) => p === page)
      ? null
      : `/api/dataservice/v1/banners?page=${page}`
  );
  if (!bannerData || bannerData.length === 0) return null;
  return (
    <div className={classes.container}>
      {bannerData.map((b) => (
        <Banner key={b.id} bannerData={b} />
      ))}
    </div>
  );
};

const Banner: FC<{ bannerData: BannerDataType }> = ({ bannerData }) => {
  const classes = useStyles();
  const [bannerStatus, setBannerStatus] = useState(true);
  const closeBanner = () => {
    setBannerStatus(false);
  };
  if (!bannerData || !bannerData.message) return null;
  return (
    <Collapse in={bannerStatus} unmountOnExit={true}>
      <Alert
        severity={bannerData?.bannerType ?? "info"}
        onClose={closeBanner}
        elevation={2}
        classes={{
          icon: classes.icon,
          root: classes.root,
          action: classes.action,
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: bannerData?.message ?? "" }}
          className={classes.bannerContainer}
        />
      </Alert>
    </Collapse>
  );
};

export default BannerContainer;
