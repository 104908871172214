import { createContext, Dispatch, SetStateAction } from "react";

export default createContext<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hoverOpen: boolean;
  isCiscoNetwork: boolean;
  setIsCiscoNetwork: Dispatch<SetStateAction<boolean>>;
}>({
  open: false,
  setOpen: () => ({}),
  hoverOpen: false,
  isCiscoNetwork: false,
  setIsCiscoNetwork: () => ({}),
});
