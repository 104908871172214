import { FEATURE_KEY, ROLE } from "constant";
import { useRouter } from "next/router";
import { AuthProvider } from "pages";
import { FC, useContext, useRef } from "react";
import { useAccountById } from "components/summary/hooks";
import { Skeleton } from "@material-ui/lab";
import StatusChip from "./StatusChip";
import FavoriteHeart from "components/summary/main/FavoriteHeart";
import { Account, Site } from "types/portfolio";
import { currencyFormatter } from "utils";
import CountryRenderer from "components/portfolio/cellRenderer/CountryRenderer";
import moment from "moment";
import AccountTeamDialog from "components/portfolio/components/AccountTeamDialog";
import ReactDOM from "react-dom";

interface ConfigItem {
  label: string;
  field: string;
  value?: any;
}

interface linkItem {
  label: string;
  href: string;
  enable: boolean;
}

// result detail info config
const config: ConfigItem[] = [
  { label: "Status", field: "accountStatus" },
  { label: "Favorite", field: "favorite" },
  {
    label: "Organization Name",
    field: "companyName",
  },
  { label: "Site URLs", field: "sites" },
  {
    label: "Invoice ARR (Collab + Non-Collab)",
    field: "invoiceArrTotal",
  },
  {
    label: "Partner Name",
    field: "partnerAccountName",
  },
  { label: "Country", field: "country" },
  {
    label: "Subscription Renewal Date",
    field: "renewalDate",
  },
  {
    label: "Cisco Collab CSM",
    field: "ciscoCsmName",
  },
  {
    label: "Partner CSM",
    field: "partnerCsmName",
  },
  {
    label: "CCSE",
    field: "ccseName",
  },
  {
    label: "Calling ePSM",
    field: "callingEpsmName",
  },
  {
    label: "Calling PSM",
    field: "callingPsmName",
  },
  {
    label: "DSM",
    field: "dsmName",
  },
];

const hideForPartnerCols = [
  "ccseName",
  "callingEpsmName",
  "callingPsmName",
  "dsmName",
];

const SearchDetailComponent: FC<{
  showDetail: boolean;
  curAccountId: string;
  curConfidential: boolean;
  type?: string;
}> = ({
  showDetail = false,
  curAccountId,
  curConfidential,
  type = "default",
}) => {
  const authDetail = useContext(AuthProvider);
  const router = useRouter();
  const isEmbedded =
    (router.pathname === "/spng/playbook" ||
      router.pathname === "/spng/activity") &&
    type !== "pop";
  const favoriteRef = useRef<HTMLDivElement | null>(null);
  const { data: detail } = useAccountById(curAccountId ?? "");

  const handleValue = (label: string, value: any) => {
    switch (label) {
      case "Status":
        return (
          <StatusChip
            type={`${value === "Inactive" ? "INACTIVE" : "ACTIVE"}`}
          />
        );
      case "Favorite":
        return (
          <div ref={favoriteRef}>
            <FavoriteHeart
              accountId={value?.accountId as string}
              disableJump={true}
            />
          </div>
        );
      case "Organization Name":
      case "Partner Name":
      case "Partner CSM":
      case "CCSE":
      case "Calling ePSM":
      case "Calling PSM":
      case "DSM":
        return value ?? "N/A";
      case "Cisco Collab CSM":
        return value ?? "None";
      case "Site URLs":
        const validSites = value?.filter((v: Site) => v.siteKey);
        const siteArr: string[] = [];
        validSites?.forEach((item: Site) => {
          siteArr.push(
            item.primarySite
              ? `${item.siteName} (Primary)`
              : item.siteName ?? ""
          );
        });
        return validSites?.length ? siteArr.join(", ") : "N/A";
      case "Invoice ARR (Collab + Non-Collab)":
        return value !== null ? currencyFormatter(value ?? 0) : "";
      case "Country":
        return <CountryRenderer data={value} type="searchInfo" />;
      case "Subscription Renewal Date":
        return value ? moment(value).format("MM/DD/YYYY") : "N/A";
      default:
        return;
    }
  };

  let quickLinkList = [
    {
      label: "View Subscription Details",
      href: `/spng/summary?accountId=${curAccountId}`,
      enable:
        router?.pathname !== "/spng/summary" &&
        authDetail?.features?.includes(FEATURE_KEY.VIEW_SUBSCRIPTION_DETAIL),
    },
    {
      label: "View Customer Contacts",
      href: `/spng/summary?accountId=${curAccountId}`,
      enable:
        router?.pathname !== "/spng/summary" &&
        authDetail?.features?.includes(FEATURE_KEY.VIEW_SUBSCRIPTION_DETAIL),
    },
    {
      label: "View Activity",
      href: `/spng/summary/activity?accountId=${curAccountId}`,
      enable:
        router?.pathname !== "/spng/summary/activity" &&
        authDetail?.features?.includes(FEATURE_KEY.VIEW_ACTIVITY),
    },
    {
      label: "View Cisco Collab Success Plans",
      href: `/spng/summary/expand-plan?accountId=${curAccountId}&managementType=0&version=current`,
      enable: authDetail?.features?.includes(
        FEATURE_KEY.VIEW_CISCO_EXPAND_PLAN
      ),
    },
    {
      label: "View Cisco Account Team",
      href: "",
      enable: authDetail?.features?.includes(
        FEATURE_KEY.PORTFOLIO_ACCOUNT_TEAM
      ),
    },
    {
      label: "View Meetings Usage",
      href: `/spng/summary/usage/meeting?accountId=${curAccountId}`,
      enable: router?.pathname !== "/spng/summary/usage/meeting",
    },
    {
      label: "View Messaging Usage",
      href: `/spng/summary/usage/teams?accountId=${curAccountId}`,
      enable: router?.pathname !== "/spng/summary/usage/teams",
    },
    {
      label: "View Calling Usage",
      href: `/spng/summary/usage/calling?accountId=${curAccountId}`,
      enable: router?.pathname !== "/spng/summary/usage/calling",
    },
    {
      label: "View Meeting Host Usage",
      href: `/spng/summary/usage/host?accountId=${curAccountId}`,
      enable:
        router?.pathname !== "/spng/summary/usage/host" &&
        authDetail?.role !== ROLE.CISCO_NON_CSM_ROLE,
    },
  ];

  if (curConfidential) {
    quickLinkList = quickLinkList.filter(
      (link) =>
        !(
          link.href.includes("/usage/teams") ||
          link.href.includes("/usage/calling")
        )
    );
  }

  const navigateClick = (item: linkItem) => () => {
    switch (item.label) {
      case "View Subscription Details":
        // need page scroll
        window.open(
          `/spng/summary?accountId=${curAccountId}&section=subscription`,
          "_blank"
        );
        break;
      case "View Customer Contacts":
        // need page scroll
        window.open(
          `/spng/summary?accountId=${curAccountId}&section=contact`,
          "_blank"
        );
        break;
      case "View Cisco Account Team":
        // open dialog
        const wrapper = document.createElement("div");
        document.body.appendChild(wrapper);
        ReactDOM.render(
          <AccountTeamDialog
            account={detail}
            dialogClose={() => {
              wrapper.remove();
            }}
            authDetail={authDetail}
          />,
          wrapper
        );
        break;
      default:
        window.open(item.href, "_blank");
        break;
    }
  };

  return (
    <>
      {showDetail ? (
        <div
          style={{
            display: "flex",
            background: "#F8F9FB",
            paddingBottom: 0,
            flexDirection: isEmbedded ? "row-reverse" : "row",
          }}
        >
          <div
            style={{
              maxWidth: 240,
              maxHeight: "60vh",
              padding: 16,
              overflowY: "scroll",
            }}
          >
            {detail ? (
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: "14px",
                  color: "#333",
                  marginBottom: 14,
                }}
              >
                {detail?.accountName}
              </p>
            ) : (
              <Skeleton width={100} height={36} />
            )}
            {config?.map((item) =>
              hideForPartnerCols.includes(item.field) &&
              authDetail?.role === ROLE.PARTNER_ROLE ? null : (
                <div key={item.field} style={{ marginBottom: 16 }}>
                  <p style={{ color: "#898989", fontSize: 12 }}>{item.label}</p>
                  {detail ? (
                    <div style={{ color: "#333", fontSize: 12 }}>
                      {handleValue(
                        item.label,
                        item.label !== "Favorite"
                          ? detail[item.field as keyof Account]
                          : detail
                      )}
                    </div>
                  ) : (
                    <Skeleton width={100} height={36} />
                  )}
                </div>
              )
            )}
          </div>
          <div
            style={{
              maxWidth: 230,
              padding: 16,
              borderRight: isEmbedded ? "1px solid #ddd" : "none",
            }}
          >
            <p
              style={{
                fontSize: 14,
                fontWeight: 600,
                color: "#333",
                marginBottom: 20,
              }}
            >
              Quick Link
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {quickLinkList.map((link) =>
                link.enable ? (
                  <a
                    key={link.label}
                    style={{
                      marginBottom: 16,
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#007AA3",
                    }}
                    onClick={navigateClick(link)}
                  >
                    {link.label} &gt;
                  </a>
                ) : null
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SearchDetailComponent;
