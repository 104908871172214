import { FC } from "react";
import { makeStyles, Button, ButtonProps } from "@material-ui/core";
import { trackButton } from "utils";

const useStyles = makeStyles(() => ({
  root: {
    "&&:focus": {
      boxShadow: "0 0 4px 2px #DEDEDE !important",
    },
    fontSize: "0.75rem !important",
    lineHeight: "1rem !important",
    height: "1.5rem !important",
    padding: "0.1875rem 0.5rem !important",
    textTransform: "none",
  },
  blueReverse: {
    border: "1px solid #007AA3",
    borderRadius: 20,
    color: "#007AA3",
    textTransform: "none",
    fontFamily: "CiscoSans",
    "&:hover": {
      color: "#007AA3",
      backgroundColor: "white",
    },
    "&:focus": {
      border: "1px solid #007AA3",
    },
    "&:active": {
      color: "#007AA3",
      backgroundColor: "white",
      border: "1px solid #007AA3",
    },
    "&:disabled": {
      border: "none",
      pointerEvents: "none",
    },
    padding: "0px 8px",
    fontSize: 14,
    fontWeight: 400,
  },
}));

interface MButtonProps extends ButtonProps {
  active?: boolean;
  buttonType?: "blue" | "orange" | "blue-reverse" | "red";
  buttonSize?: number;
  trackName?: string;
}

const MButton: FC<MButtonProps> = ({
  children,
  active,
  buttonType,
  size,
  trackName,
  onClick,
  buttonSize,
  style,
  className,
  ...rest
}) => {
  const classes = useStyles(active);
  const trackClick = (e: any) => {
    if (onClick) {
      if (trackName) {
        trackButton(trackName);
      }
      onClick(e);
    }
    return undefined;
  };
  return (
    <Button
      className={`${buttonSize ? "" : classes.root} md-button md-button--${
        buttonSize ?? 28
      } md-button--${
        !!!buttonType ? (active ? "dark-gray" : "white") : buttonType
      } ${
        buttonType === "blue-reverse" ? classes.blueReverse : ""
      } ${className}`}
      size={size ?? "small"}
      {...rest}
      style={{ textTransform: "none", ...style }}
      onClick={trackClick}
    >
      {children}
    </Button>
  );
};

export default MButton;
