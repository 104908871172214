import { FC, useContext } from "react";
import { AuthProvider } from "pages";
import { FEATURE_KEY } from "constant";

interface AuthCheckProps {
  id: FEATURE_KEY | FEATURE_KEY[];
}

const AuthCheck: FC<AuthCheckProps> = ({ children, id }) => {
  try {
    const authDetail = useContext(AuthProvider);
    return authDetail?.features.some((x) =>
      typeof id === "string" ? x === id : id.includes(x)
    ) ? (
      <>{children}</>
    ) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default AuthCheck;
